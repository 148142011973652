import React, { useContext } from 'react'
import { DataContext } from '../DataContext'
import ParamResetSelector from '../../../../elem/chart/ParamResetSelector'

const ResetParamDropDown = () => {
    const { resetZoom, resetParams, paramResetExpanded, toggleParamResetExpanded } = useContext(DataContext)

    const onResetParams = () => {
        resetParams()
        toggleParamResetExpanded(false)
    }

    const onResetZoom = () => {
        resetZoom()
        toggleParamResetExpanded(false)
    }

    return (
        <ParamResetSelector
            resetExpanded={paramResetExpanded}
            resetParamsFunction={onResetParams}
            resetZoomFunction={onResetZoom}
        />
    )
}

export default ResetParamDropDown