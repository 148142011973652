import React from 'react'

import DataContextProvider from './DataContext'
import Panel from '../../../elem/panel/Panel'
import { aggregatePanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import AggregateHeader from './AggregateHeader'
import ResetDropdown from './ResetDropDown'
import ParamDropdown from './ParamDropdown'
import AggregateChart from './AggregateChart'

export default ({ width, name }) => {
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <AggregateHeader />
                    <ResetDropdown />
                    <ParamDropdown />
                    <AggregateChart name={name} />
                </DataContextProvider>
            </div>
        </Panel>
    )
}