import dayjs from 'dayjs'
import { getInputType } from '../form/formFieldParser'
import {
    extractNumericalValue,
    replaceQuotesWithNulls,
    extractDateValue,
} from './numerical'


const convertFieldDuplicateString = IsFieldDuplicate => {
    if (IsFieldDuplicate) {
        return ' - Duplicate'
    } else {
        return ''
    }
}

const issuesInNavgroup = (groupName, errorState, uploadConfig, level) => {
    const flattenedIssues = Object.keys(errorState).reduce((acc, curr) => {
        const dataAccessor = curr.split('[')[0]
        const errors = errorState[curr]
        if (acc[dataAccessor]) {
            return {
                ...acc,
                [dataAccessor]: [...acc[dataAccessor], ...errors]
            }
        } else {
            return {
                ...acc,
                [dataAccessor]: errors
            }
        }
    }, {})
    const fieldsAssociatedWithNavGroup = uploadConfig.filter(c => c.SectionGroupName === groupName)
    const navGroupHasIssues = Object.keys(flattenedIssues).map(dataAccessor => {
        var issues = flattenedIssues[dataAccessor]
        var hasIssues = issues.filter(x => x.severity === level).map(issue => fieldsAssociatedWithNavGroup.find(field => {
                if (issue.fieldName === "") {
                    return field.DataAccessor === dataAccessor
                } else {
                    return field.DataAccessor === dataAccessor && field.ColumnName === issue.fieldName
                }
            })
        ).some(x => x)
        return hasIssues
    }).some(x => x)
    return navGroupHasIssues
}

const getValueFromDataRow = (fieldName, newRow, config) => {
    if (fieldName.includes('Select') && !fieldName.includes('DateSelect')) {
        const inputName = fieldName.replace('Select', '')
        const associatedConfig = config.find(
            x => x.ColumnName === inputName
        )
        const values = associatedConfig.Values
        const value = newRow[inputName]
        const valueEntry = values.find(x => String(x.code) === String(value))
        if (
            (value === null || typeof(value) === 'undefined' || value === '') &&
            (valueEntry === null || typeof(valueEntry) === 'undefined')
        ) {
            return '' // https://coordinatesolutions.atlassian.net/browse/AW-564?focusedCommentId=52088 this comment explains the bug this resolves
        } else
            return {
                active: associatedConfig.active,
                value: value,
                label: valueEntry.codedescription,
            }
    } else {
        if (fieldName.includes('DateSelect')) {
            return newRow[fieldName.replace('DateSelect', '')]
                ? dayjs(newRow[fieldName.replace('DateSelect', '')])
                      .format('MM/DD/YYYY hh:mm A')
                      .toString()
                : ''
        } else if (fieldName.includes('Select')) {
            return newRow[fieldName.includes('Select')]
        } else return newRow[fieldName]
    }
}


const errorsInNavgroup = (groupName, errorState, uploadConfig) => issuesInNavgroup(groupName, errorState, uploadConfig, 'Error')

const warningsInNavgroup = (groupName, errorState, uploadConfig) => issuesInNavgroup(groupName, errorState, uploadConfig, 'Warning')

const removeInputTypeNames = (o, uploadConfig) => {
    return Object.keys(o).reduce((acc, curr) => {
        const formEntry = o[curr]
        const isSelect = curr.includes('Select')
        const isDate = curr.includes('DateSelect')
        const strippedFieldName = curr
            .replace('Input', '')
            .replace('DateSelect', '')
            .replace('Select', '')
        const associatedFieldConfig = uploadConfig.find(
            x => x.ColumnName === strippedFieldName
        )
        if (isDate) {
            return {
                ...acc,
                [strippedFieldName]: extractDateValue(formEntry),
            }
        }
        if (isSelect && formEntry) {
            if (!associatedFieldConfig) {
                return {
                    ...acc,
                    [strippedFieldName]: extractNumericalValue(formEntry.value)
                }
            }
            return {
                ...acc,
                [strippedFieldName]:
                    getInputType(associatedFieldConfig.DataType) === 'number'
                        ? extractNumericalValue(formEntry.value)
                        : replaceQuotesWithNulls(formEntry.value),
            }
        }
        return {
            ...acc,
            [strippedFieldName]:
                getInputType(associatedFieldConfig ? associatedFieldConfig.DataType : null) === 'number' &&
                formEntry
                    ? extractNumericalValue(formEntry)
                    : replaceQuotesWithNulls(formEntry),
        }
    }, {})
}

const getValuesFromData = (data, uploadConfig) => {
    const newData = Object.keys(data).reduce((acc, groupName) => {
        const associatedData = data[groupName]
        if (Array.isArray(associatedData)) {
            return {
                ...acc,
                [groupName]: associatedData.map(d =>
                    removeInputTypeNames(d, uploadConfig)
                ),
            }
        } else {
            return {
                ...acc,
                [groupName]: removeInputTypeNames(associatedData, uploadConfig),
            }
        }
    }, {})
    return newData
}

const isChanged = (
    uploadChanges,
    dataAccessor,
    name,
    idx,
    submissionState
) => {
    if (uploadChanges && uploadChanges[dataAccessor]) {
        if (isNaN(idx)) {
            return !!uploadChanges[dataAccessor].some(x => x.column === name)
        } else {
            if (submissionState && submissionState[dataAccessor] && submissionState[dataAccessor][idx]) {
                const uploadData = submissionState[dataAccessor][idx]
                const tableIdColumn = dataAccessor.slice(0, 1).toUpperCase() + dataAccessor.slice(1) + 'ID'
                const id = uploadData[tableIdColumn]
                
                if (typeof id !== 'undefined') {
                    const delta = !!uploadChanges[dataAccessor].some(x => x.column === name && x[tableIdColumn] === id)
                    return delta
                }
            }
        }
    }
    return false
}

const getWarningFromErrorState = (errorState, name, rowIdx, dataAccessor) => {
    if (errorState) {
        if (isNaN(rowIdx) && errorState[dataAccessor]) {
            const e = errorState[dataAccessor].find(x => x.fieldName === name && x.severity === 'Warning')
            return e
        } else {
            if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                    const e = errorState[`${dataAccessor}[${rowIdx}]`].find(x => x.severity === 'Warning' && x.fieldName === name)
                    return e ? e : null
                }
            }
            return null
        }
    }
}

const getApiErrorFromErrorState = (errorState, name, rowIdx, dataAccessor, formError) => {
    if (errorState && !formError) {
        if (isNaN(rowIdx) && errorState[dataAccessor]) {
            const e = errorState[dataAccessor].find(x => x.fieldName === name && x.severity === 'Error')
            return e
        } else {
            if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                    const e = errorState[`${dataAccessor}[${rowIdx}]`].find(x => x.severity === 'Error' && x.fieldName === name)
                    return e ? e : null
                }
            }
            return null
        }
    }
}
export { removeInputTypeNames, getValuesFromData, isChanged, getApiErrorFromErrorState, getWarningFromErrorState, warningsInNavgroup, errorsInNavgroup, convertFieldDuplicateString, getValueFromDataRow }