import React, { useContext } from 'react'
import { FaAngleUp, FaAngleDown, FaCompressArrowsAlt, FaExpandArrowsAlt, } from 'react-icons/fa'

import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'
import PanelHeader from '../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import ResetDropDownButton from '../../../elem/chart/ResetDropDownButton'
import ParamSelectorButton from '../../../elem/chart/ParamSelectorButton'
import { AppStateContext } from '../AppStateContext'

export default () => {
    const {
        displayParamWindowDropdown,
        toggleParamWindowDropdown,
        paramList,
        setCollapsed,
        isCollapsed,
        resetExpanded, 
        toggleResetExpanded,
    } = useContext(DataContext)
    const { expandedMenu, setExpandedMenu } = useContext(AppStateContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Aggregate Chemistry Sample Results Chart</div>
            <div className="panelButtons">
                <ResetDropDownButton 
                    resetExpanded={resetExpanded} 
                    toggleResetExpanded={toggleResetExpanded}
                />
                {/* <ResetZoomButton resetFunction={resetZoom} /> */}
                {paramList && paramList.length ? (
                    <ParamSelectorButton
                        displayParamWindowDropdown={
                            displayParamWindowDropdown
                        }
                        toggleParamWindowDropdown={
                            toggleParamWindowDropdown
                        }
                    />
                ) : null}
                {/* <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                /> */}
                <div className="panelHeaderIconWrapper">
                    {expandedMenu === 'SampleAggregateChart' ? null : (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                isCollapsed
                                    ? `Expand Aggregate`
                                    : `Hide Aggregate`
                            }`}
                            data-for={`aggregate-panel-toggle`}
                            onClick={() =>
                                setCollapsed(!isCollapsed)
                            }
                        >
                            {isCollapsed ? (
                                <FaAngleUp />
                            ) : (
                                <FaAngleDown />
                            )}        
                        </div>
                    )}
                    <div
                        className={`panelHeaderIconDiv`}
                        data-tip={`${expandedMenu === 'SampleAggregateChart'
                            ? `Collapse`
                            : `Expand to Full Screen`
                            }`}
                        data-for={`SampleAggregateChart`}
                        onClick={() => setExpandedMenu(current => current === 'SampleAggregateChart' ? null : 'SampleAggregateChart')}
                    >
                        <IconContext.Provider
                            value={{
                                className: `panelHeaderIcon`,
                            }}
                        >
                            {expandedMenu === 'SampleAggregateChart' ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
                        </IconContext.Provider>
                        <Tooltip id={'SampleAggregateChart'} />
                    </div>
                </div>
            </div>
        </PanelHeader>
    )
}
