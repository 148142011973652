import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useContext,
    useRef,
    useCallback,
} from 'react'
import dayjs from 'dayjs'
import localForage from 'localforage'
import { debounce } from 'debounce'

import withConfig from '../../../wrappers/withConfig'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { AppStateContext } from '../AppStateContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { UserContext } from '../../../wrappers/UserContext'

import { getQueryStringFromParams } from '../../../../utils/params'
import toast from '../../../elem/Toast'
import { getRoles, hasAccessToPWS } from '../../../../utils/user/permissions'
import { transform } from 'ol/proj'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature';

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    const [allData, setAllData] = useState({})
    const [filteredData, setFilteredData] = useState(null)
    const [layerData, setLayerData] = useState([])
    const filterRef = useRef([])
    const [loading, setLoading] = useState(true)
    const [tooltipLoading, setTooltipLoading] = useState(true)
    const [latLongFeature, setLatLongFeature] = useState()
    const { API_URL } = config
    
    const { params, setParams } = useContext(ParameterContext)
    const { setMapState, mapState: { allFeatures, map, mapSelectionIds } } = useContext(AppStateContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { client, user } = useContext(UserContext)

    const [tooltipData, setTooltipData] = useState({})
    const [tooltipFilterData, setTooltipFilterData] = useState({})
    const [isSubmissionsMap, setIsSubmissionsMap] = useState(false)

    // load all map data from cache first, network request second
    useMemo(() => {
        setLoading(true)
        client.getUser().then(user => {
            const roles = getRoles(user)
            const isAgency = hasAccessToPWS(roles)
            const storageItemName = `mapData:${isAgency ? 'agency': 'public'}`
            localForage.getItem(storageItemName).then(cachedData => {
                if (cachedData) {
                    const json = JSON.parse(cachedData)
                    if (json && json.data && json.expires) {
                        if (dayjs() < dayjs(json.expires)) {
                            setLoading(false)
                            return setAllData(json.data)
                        }
                    }
                }
                authenticatedFetch(`${API_URL}/well/map`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers':
                            'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                    },
                    body: JSON.stringify({selectedIds: "", mapSelectionIds: "" })
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        const sessionObject = {
                            expires: dayjs().add(1, 'day'),
                            data: response.data,
                        }
                        localForage
                            .setItem(storageItemName, JSON.stringify(sessionObject))
                            .then(() => {
                                setAllData(response.data)
                            })
                            .catch(e => {
                                toast({
                                    level: 'info',
                                    message:
                                        'Well Map: Unable to store data locally. This will effect map loading times.',
                                })
                            })
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Well Map: ' +
                                (e.message
                                    ? e.message
                                    : 'Unable to connect to the server. Please try again later.'),
                        })
                    })
                    .finally(() => setLoading(false))
            })
        })
        .catch(() => {})
    }, [user])

    // load map layer data
    useEffect(() => {
        authenticatedFetch(`${API_URL}/map/layers`)
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setLayerData(response)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Map Layers:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
    }, [API_URL])

    const filterData = useCallback(params => {
        if (allData.features) {
            const parameterString = getQueryStringFromParams(params, true)
            if (parameterString || mapSelectionIds !== "") {
                authenticatedFetch(`${API_URL}/well/map?${parameterString}`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers':
                            'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                    },
                    body: JSON.stringify({ mapSelectionIds })
                })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    const d = response.data.features.map(
                        x => x.properties.FacilityID
                    )
                    setFilteredData(d)
                    filterRef.current = d
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Map: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
            } else {
                const d = allData.features.map(
                    x => x.properties.ClearinghouseNumber
                )
                setFilteredData(d)
            }
        }
    }, [allData, mapSelectionIds])

    const fetchTooltipDataDebounce = debounce(
        facilityID => {
            authenticatedFetch(`${API_URL}/well/tooltip/${facilityID}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setTooltipData(response.data)
                    setTooltipFilterData(response.filterData)
                    setTooltipLoading(false)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Map: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
        },
        300,
        false
    )

    const fetchTooltipData = facilityID => {
        setTooltipLoading(true)
        fetchTooltipDataDebounce.clear()
        fetchTooltipDataDebounce(facilityID)
    }

    const updateFilteredData = newData => {
        const f = filterRef.current
        const updatedData = [...f, ...newData]
        filterRef.current = updatedData
        setFilteredData(updatedData)
        setMapState(prevMapState => ({
            ...prevMapState,
            filteredData: updatedData,
        }))
    }

    const clearSelectionLayer = () => {
        setMapState(mapState => ({ ...mapState, selectedFeatures: [] }))
    }

    // fetch data on parameter changes
    useEffect(() => {
        filterData(params)
    }, [params['well'], allData, mapSelectionIds])

    const getFeatureWithLatLong = useCallback((lat, long) => {
        //unselect all features to have a clean map
        setMapState(prevMapState => ({
            ...prevMapState,
            selectedFeatures: []
        }))
        // Looks up whether the feature exists in the map 
        const lookedupFeature = allFeatures.find(x => {
            const xLat = x.get('Latitude')
            const xLong = x.get('Longitude')

            return xLat === lat && xLong === long
            // const featuresInLatLong = transform(x.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326')
            // let xLat = featuresInLatLong[1]
            // let xLong = featuresInLatLong[0] 
            // //finding issue where the converted lat long that we get from the feature is longer than the accepted lat long 
            // //for example: inputted valid long -98.330777612, long that we get from the transformation: -98.33077761200002
            // // this makes it so we can't use === to compare 
            // // return xLong.toString().includes(long) && xLat.toString().includes(lat)
            // const floatLat = Number(lat)
            // const floatLong = Number(long)
            // const latDiff = Math.abs(floatLat - xLat)
            // const longDiff = Math.abs(floatLong - xLong)
            // const maxDiff = 0.0000001
            // return latDiff < maxDiff && longDiff < maxDiff  
        })
        if (lookedupFeature) {
            setMapState(prevMapState => ({
                ...prevMapState,
                selectedFeatures: [lookedupFeature]
            }))
            setLatLongFeature(lookedupFeature)
            map.getView().fit(lookedupFeature.getGeometry(), {minResolution: 15})
        }
        else {
            // create a point in the desired coordinates to zoom into it, I believe this point is not displayed in the map...
            const featuresInXYCoords = transform([parseFloat(long), parseFloat(lat)], 'EPSG:4326','EPSG:3857')
            
            let point = new Point(featuresInXYCoords)
            var feature = new Feature(point)
            setLatLongFeature(feature)
            //zooms into the lat long in the map
            map.getView().fit(point, { minResolution: 15 })
        }
    }, [allFeatures])

    return (
        <DataContext.Provider
            value={{
                data: allData,
                filteredData,
                layerData,
                tooltipData,
                tooltipFilterData,
                tooltipLoading,
                fetchTooltipData,
                setFilteredData,
                updateFilteredData,
                clearSelectionLayer,
                params,
                setParams,
                loading,
                setLoading,
                getFeatureWithLatLong,
                latLongFeature,
                setIsSubmissionsMap,
                isSubmissionsMap,
                
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
