import React, { useContext, useState } from 'react'

import Filter from './Filter'
import List from './List'
import DataContextProvider from './DataContext'
import Panel from '../../../elem/panel/Panel'
import { samplePanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import { AppStateContext } from '../AppStateContext'
import SampleHeader from './SampleHeader'

const Explorer = ({ width }) => {
    const name = `Sample`
    const {
        filterState,
        updateFilterState,
        syncFilterState,
        togglePromotedRecords,
        promotedRecords,
        expandedMenu,
        setExpandedMenu,
    } = useContext(AppStateContext)
    
    const [isCollapsed, setCollapsed] = useState(false)
    const filterExpanded = filterState['sample']
    const toggleFilter = updateFilterState.bind(this, 'sample')

    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <SampleHeader
                        name={name}
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        promotedRecords={promotedRecords['sample']}
                        togglePromotedRecords={togglePromotedRecords}
                        isCollapsed={isCollapsed}
                        setCollapsed={setCollapsed}
                        isExpanded={expandedMenu === name}
                        setExpandedMenu={setExpandedMenu}
                    />
                    {!isCollapsed && (<>
                        <Filter
                            width={width}
                            filterExpanded={filterExpanded}
                            syncFilterState={syncFilterState}
                            promotedRecords={promotedRecords}
                        />
                        <List
                            filterExpanded={filterExpanded}
                            toggleFilter={toggleFilter}
                            promotedRecords={promotedRecords}
                        />
                    </>)}
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default Explorer
