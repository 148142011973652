import React from 'react'

import ChartTabs from './ChartTabs'
import ChartControls from './ChartControls'
import TimeWindowDropdown from './TimeWindowDropdown'
import ParamDropdown from './sampleChart/ParamDropdown'
import ResetParamDropDown from './sampleChart/ResetParamDropdown'
import DetailSampleChart from './sampleChart/DetailSampleChart'
import DetailDepthChart from './depthProfile/DetailDepthChart'

const DetailChart = () => {
    return (
        <div>
            <ChartTabs />
            <ChartControls />
            <ResetParamDropDown />
            <ParamDropdown />
            <TimeWindowDropdown />
            <DetailSampleChart />
            <DetailDepthChart />
        </div>
    )
}

export default DetailChart