
import ReviewSubmission from './form/ReviewSubmission'
import FacilityAliasForm from './form/FacilityAliasForm'
import DNRInformationTab from './form/dnrInformationTab/DNRInformationTab'
import FacilitySelectionForm from './form/FacilitySelection'
import ViewMapTab from './form/viewMapTab/ViewMapTab'

export default (formType, configuredNavGroups, isSearchTabVisible, isReview, isViewMapVisible) => {

    let allNavGroups = configuredNavGroups

    const aliasTab = {
        groupName: 'Well Alias Information',
        requires: null,
        type: 'component',
        component: FacilityAliasForm,
        dataAccessor: 'facilityAlias'
    }

    const reviewTab = {
        groupName: 'Review',
        requires: null,
        type: 'component',
        component: ReviewSubmission,
        dataAccessor: 'facility',
    }

    const dnrInformationTab = {
        groupName: 'DNR Information',
        requires: null,
        type: 'component',
        component: DNRInformationTab,
        dataAccessor: 'dnrInformation'
    }

    const searchTab = {
        groupName: 'Well Search',
        requires: null,
        type: 'component',
        component: FacilitySelectionForm,
        dataAccessor: 'facility',
    }

    const viewMap = {
        groupName: 'View in Map',
        requires: null,
        type: 'component',
        component: ViewMapTab,
        dataAccessor: 'viewMap',
    }
    
    allNavGroups = [
        aliasTab,
        dnrInformationTab,
        ...allNavGroups,
    ]

    if(isSearchTabVisible) {
        allNavGroups = [searchTab, ...allNavGroups]
    }
    
    if (isViewMapVisible) {
        allNavGroups = [ ...allNavGroups, viewMap]
    }

    if (isReview) {
        allNavGroups = [ ...allNavGroups, reviewTab]
    }
    
    return allNavGroups
} 