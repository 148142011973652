import React, { useContext } from 'react'
import { DataContext } from './DataContext'
import ParamResetSelector from '../../../elem/chart/ParamResetSelector'

const ResetDropdown = () => {
    const { resetZoom, resetParams, resetExpanded, toggleResetExpanded } = useContext(DataContext)

    const onResetAnalytes = () => {
        resetParams()
        toggleResetExpanded(false)
    }

    const onResetZoom = () => {
        resetZoom()
        toggleResetExpanded(false)
    }

    return (
        <ParamResetSelector
            resetExpanded={resetExpanded}
            resetParamsFunction={onResetAnalytes}
            resetZoomFunction={onResetZoom}
        />
    )
}

export default ResetDropdown