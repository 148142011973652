import React, { useContext } from 'react'
import { PanelStyleContext } from '../../../elem/panel/PanelStyleContext'
import { DataContext } from './DataContext'

const ChartTabs = () => {
    const {
        visibleChartTab,
        setVisibleChartTab,
        toggleParamWindowDropdown,
     } = useContext(DataContext)
    const { tabClass } = useContext(PanelStyleContext)
    return (
        <div className={`tabs is-small is-boxed is-fullwidth ${tabClass} chartTabs`}>
            <ul>
                <li
                    className={visibleChartTab === 'sampleResult' ? 'is-active' : ''}
                    onClick={() => {
                        setVisibleChartTab('sampleResult')
                    }}
                >
                    <div>
                        <span>Chemistry Sample Results</span>
                    </div>
                </li>
                <li
                    className={visibleChartTab === 'depth' ? 'is-active' : ''}
                    onClick={() => {
                        setVisibleChartTab('depth')
                        toggleParamWindowDropdown(false)
                    }}
                >
                    <div>
                        <span>Depth Profile</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ChartTabs