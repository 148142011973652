import React, { useContext } from 'react'

import { formatValue } from '../../../../../utils/table/parseColumns'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'
import { AppStateContext } from '../../AppStateContext'

const mapDataToLabels = props => {
    return Object.keys(props.formFields).reduce((acc, fieldName) => {
        const field = props.formFields[fieldName]
        const fieldLabel = field.Prompt
        const fieldColumn = field.ColumnName
        const fieldValue = props.data[fieldColumn]
        return {
            ...acc,
            [fieldColumn]: {
                fieldLabel,
                fieldValue,
            },
        }
    }, {})
}

const FieldComponent = ({ fieldLabel, fieldValue, size }) => {
    const textSize = size ? size : '7'
    return (
        <div className="field is-horizontal">
            <div className={`label is-size-${textSize}`}>{fieldLabel}</div>
            <div
                className={`value margin-left-sm is-size-${textSize}`}
                dangerouslySetInnerHTML={{
                    __html: formatValue(fieldValue, true),
                }}
            />
        </div>
    )
}

const WellLinkComponent = ({ fieldLabel, fieldValue }) => {
    const appContext = useContext(AppStateContext)
    if (!appContext) {
        return (
            <FieldComponent fieldLabel={fieldLabel} fieldValue={fieldValue} />
        )
    }
    const { updateDetailState } = appContext

    return (
        <div className="field is-horizontal">
            <div className="label is-size-7">{fieldLabel}</div>
            <div
                className="value margin-left-sm is-size-7 linkCell"
                onClick={() => {
                    updateDetailState('well', {
                        visible: true,
                        facilityID: fieldValue,
                    })
                }}
            >
                {formatValue(fieldValue)}
            </div>
        </div>
    )
}
export default ({ tooltipData, tooltipFilterData, isSubmissionsMap }) => {
    const { borderClass } = useContext(PanelStyleContext)

    const tooltipDetailProps = {
        formFields: tooltipFilterData,
        data: tooltipData,
        fieldGroups: [...new Set(tooltipFilterData.map(x => x.GroupName))],
    }

    const tooltipDetailData = mapDataToLabels(tooltipDetailProps)
    return (
        <div className={`tooltipDataWrapper ${borderClass}`}>
            <div className="columns is-multiline">
                <div className="column is-full">
                    { isSubmissionsMap 
                        ? <FieldComponent {...tooltipDetailData['ClearinghouseNumber']} />
                        : <WellLinkComponent {...tooltipDetailData['ClearinghouseNumber']} />
                    }
                </div>
                <div className="column is-full">
                    <FieldComponent
                        {...tooltipDetailData['DNRRegistrationNumber_Full']}
                    />
                </div>
                <div className="column is-two-fifths">
                    <FieldComponent {...tooltipDetailData['DNRLink']} />
                </div>
                <div className="column is-three-fifths">
                    <FieldComponent {...tooltipDetailData['FacilityType']} />
                </div>
                <div className="column is-two-fifths">
                    <FieldComponent {...tooltipDetailData['WellDepth']} />
                </div>
                <div className="column is-full">
                    <FieldComponent
                        {...tooltipDetailData['FacilitySampleCount']}
                    />
                </div>
                <div className="column is-half">
                    <FieldComponent {...tooltipDetailData['LastSampleDate']} />
                </div>
                {tooltipDetailData['LastTestedForPesticides'].fieldValue ? (
                    <div className="column is-full">
                        <FieldComponent
                            {...tooltipDetailData['LastTestedForPesticides']}
                        />
                    </div>
                ) : null}
                <div className="column is-full">
                    <div className="label is-size-6">Nitrate Overview</div>
                </div>
                <div className="column is-three-fifths">
                    <FieldComponent
                        {...tooltipDetailData['FacilityNitrateMin']}
                    />
                </div>
                <div className="column is-two-fifths">
                    <FieldComponent
                        {...tooltipDetailData['FacilityNitrateMax']}
                    />
                </div>
                <div className="column is-three-fifths">
                    <FieldComponent
                        {...tooltipDetailData['FacilityNitrateMean']}
                    />
                </div>
                <div className="column is-two-fifths">
                    <FieldComponent
                        {...tooltipDetailData['FacilityNitrateMedian']}
                    />
                </div>
            </div>
        </div>
    )
}
