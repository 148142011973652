import React, { useEffect, useRef } from 'react'
import { dateToString } from '../../../utils/dateUtils'

export default ({ active, payload, label, coordinate, roundValues = true }) => {
    if (active) {
        const formattedLabel = dateToString(label)
        let screenW = window.innerWidth
        // let screenH = window.innerHeight
        const wrapper = document.querySelector('.recharts-tooltip-wrapper');
        if (coordinate.x + 250 >= screenW) { // if the tooltip goes out of the bounds of the screen's length
            wrapper.style.left = '-150px'
        } else {
            wrapper.style.left = ''
        }
        return (
            <div className="customTooltip">
                <p className="customTooltipLabel">{formattedLabel}</p>
                <ul className="customTooltipList">
                    {payload && payload.map((x, idx) =>
                        x.name !== 'NoTooltipItem' ? (
                            <li style={{color: x.color}} key={idx}>
                                <span className="customTooltipItemName">
                                    {x.name}
                                </span>
                                <span> : </span>
                                <span className="customTooltipItemValue">
                                    {roundValues ? Math.round(x.value * 100) / 100 : x.value}
                                </span>
                                <span className="customTooltipItemUnit">{` ${x.unit}`}</span>
                            </li>
                        ) : null
                    )}
                </ul>
            </div>
        )
    }
    return null
}
