import React from 'react'

export default ({
    selectedParams,
    toggleSelectedParam,
    paramList,
    displayParamWindowDropdown,
    colorClass,
}) => {
    if (!displayParamWindowDropdown) {
        return null
    }

    return (
        <div className="analyteDropdownWrapper">
            {paramList.map((option, idx) => {
                const active = !!selectedParams.find(
                    x => x.ParamAlias === option.ParamAlias && x.Units === option.Units
                )
                return (
                    <div
                        key={`timeoption-${idx}`}
                        className={`analyteOptionWrapper ${
                            active ? 'activeOption ' + colorClass : ''
                        }`}
                        onClick={() => {
                            toggleSelectedParam(option)
                        }}
                    >
                        <span className="analyteOptionText">
                            {option.ParamAlias}
                        </span>
                        <span>{`[${option.Units ? option.Units : 'units unknown'}]`}</span>
                    </div>
                )
            })}
        </div>
    )
}