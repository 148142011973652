import React from 'react'

import { UpArrow, DownArrow } from '../Arrows'

export default ({ toggleParamWindowDropdown, displayParamWindowDropdown, inHeader=true }) => {
    return (
        <div
            className="panelButton timeWindowOptionWrapper"
            onClick={() => toggleParamWindowDropdown(!displayParamWindowDropdown)}
        >
            <span className="panelTimeOptionText">Parameters</span>
            <span className="panelTimeOptionDropdown">
                {displayParamWindowDropdown ? (
                    <UpArrow color={'white'} className={`${inHeader ? 'is-white' : ''}`} />
                ) : (
                    <DownArrow color={'white'} className={`${inHeader ? 'is-white' : ''}`} />
                )}
            </span>
        </div>
    )
}