import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import { PanelStyleContext } from '../../../elem/panel/PanelStyleContext'
import ParamSelector from '../../../elem/chart/ParamSelector'

export default () => {
    const {
        selectedParams,
        toggleSelectedParam,
        displayParamWindowDropdown,
        toggleParamWindowDropdown,
        paramList, 
        isCollapsed
    } = useContext(DataContext)
    const { colorClass } = useContext(PanelStyleContext)

    if(isCollapsed) {
        return null
    }

    if (!paramList || !paramList.length) {
        return null
    }

    return (
        <ParamSelector
            selectedParams={selectedParams}
            paramList={paramList}
            toggleSelectedParam={toggleSelectedParam}
            toggleParamWindowDropdown={toggleParamWindowDropdown}
            displayParamWindowDropdown={displayParamWindowDropdown}
            colorClass={colorClass}
        />
    )
}
