import React, { useContext, useMemo } from 'react'

import WellExplorer from './wells/Explorer'
import SampleExplorer from './samples/Explorer'
import Map from './map/Map'
import WellDetail from './wellDetail/Detail'
import Aggregate from './aggregate/Aggregate'
import DetailsMenuDataContext from './wellDetail/DataContext'
import { detailPanel as panelClassConfig } from '../../../utils/styles/panelStyles'
import Panel from '../../elem/panel/Panel'
import { AppStateContext } from './AppStateContext'
import DetailChart from './wellDetail/DetailChart'

export default () => {
    const { expandedMenu } = useContext(AppStateContext)

    const wellExplorer = (width) => <WellExplorer key={`well-explorer`} width={width} />
    const sampleExplorer = (width) => <SampleExplorer key={`well-explorer`} width={width} />
    const map = (width) => <Map key={`map`} width={width} />
    const sampleAggregateChart = (width) => <Aggregate key={`sample-aggregate-chart`} width={width} name={`SampleAggregateChart`} />
    const detailSampleChartMenu = () => (
        <div className={`is-fullheight`}>
            <Panel panelClassConfig={panelClassConfig()}>
                <DetailsMenuDataContext >
                    <DetailChart />
                </DetailsMenuDataContext>
            </Panel>
        </div>
    )

    const menus = [
        { ordinal: 0, name: 'Sample', Component: sampleExplorer, group: 'oneThirdMenus' },
        { ordinal: 0, name: 'SampleAggregateChart', Component: sampleAggregateChart, group: 'oneThirdMenus' },
        { ordinal: 0, name: 'Map', Component: map, group: `twoThirdsMenus` },
        { ordinal: 1, name: 'Well', Component: wellExplorer, group: `twoThirdsMenus` },
        { ordinal: 0, name: 'DetailSampleChart', Component: detailSampleChartMenu },
    ]

    const currentlyExpandedComponent = useMemo(() => { 
        const expanded = menus.find(x => x.name === expandedMenu)
        return expanded ? expanded.Component('full') : null
    } , [expandedMenu])

    const oneThirdMenus = useMemo(() => {
        const defaultOneThirdMenus = menus.filter(x => x.group === `oneThirdMenus`)
        return defaultOneThirdMenus.sort(x => x.ordinal)
    }, [expandedMenu])

    const twoThirdsMenus = useMemo(() => {
        const defaultTwoThirdsMenus = menus.filter(x => x.group === `twoThirdsMenus`)
        return defaultTwoThirdsMenus.sort(x => x.ordinal)
    }, [expandedMenu])
    
    return (
        <div className="spaWrapper">
            <WellDetail />
            <div className="columns spaContainer is-full is-multiline is-1 is-variable">
                {currentlyExpandedComponent 
                    ? (
                        <div key={`expanded`} className="is-full-desktop is-full-tablet is-full-mobile expandedWrapper">
                            {currentlyExpandedComponent}
                        </div>
                    ) : null 
                }
                <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                    {oneThirdMenus.map(x => x.Component('one-third'))}
                    {/* <SampleExplorer width={'one-third'} />
                    <Aggregate width={'one-third'} /> */}
                </div>
                <div className="column is-two-thirds-desktop is-full-tablet is-full-mobile">
                    {twoThirdsMenus.map(x => x.Component('full'))}
                    {/* <Map width={'full'} />
                    <WellExplorer width={'full'} /> */}
                </div>
            </div>
        </div>
    )
}