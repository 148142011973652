import React from 'react'
import deepEqual from 'deep-equal'

import Input from '../../components/elem/form/input/Input'
import Select from '../../components/elem/form/Select'
import MultiSelect from '../../components/elem/form/MultiSelect'
import DateSelect from '../../components/elem/form/date/DateSelect'
import Checkbox from '../../components/elem/form/Checkbox'
import SubmissionInput from '../../components/features/submissions/form/Input'
import SubmissionSelect from '../../components/features/submissions/form/Select'
import DateTimeSelect from '../../components/features/submissions/form/DateTimeSelect'
import SubmissionDateSelect from '../../components/features/submissions/form/DateSelect'
import SubmissionCreatable from '../../components/features/submissions/form/Creatable'
import { useFormContext } from 'react-hook-form'

const formValidate = (
    field,
    getValues,
    setValue,
    errors,
    setError,
    clearError
) => {
    // validation on lat/lng
    if (field.ControlName === 'Radius') {
        const values = getValues()
        const latValue = values['LatitudeValue']
        const lonValue = values['LongitudeValue']
        const radiusValue = values['RadiusValue']
        if (latValue || lonValue || radiusValue) {
            if (latValue && lonValue && !radiusValue) {
                setValue('RadiusValue', field.DefaultValue)
                return
            }
            if (!(latValue && lonValue && radiusValue)) {
                setError(
                    'RadiusValue',
                    'lat/lng',
                    'Please set latitude, longitude, and radius values.'
                )
                return
            } else {
                if (errors['RadiusValue']) {
                    clearError('RadiusValue')
                    return
                }
            }
        }
    }
    if (field.ControlName === 'MCLGrouping') {
        const values = getValues()
        const mclGroupingValue = values['MCLGroupingMulti']
        const percentMCLFields = Object.keys(values).filter(x =>
            x.includes('PercentMCLValue')
        )
        const hasPercentMCLValue = percentMCLFields.filter(x => !!values[x])
            .length

        // if there are mcl groupings defined, and there are
        // mcl values input
        if (mclGroupingValue || hasPercentMCLValue) {
            // if there are two percent mcl fields, we have a 'between' operator active
            if (percentMCLFields.length === 2) {
                // if all the fields are not populated, throw an error
                if (!(mclGroupingValue && hasPercentMCLValue === 2)) {
                    if (
                        !errors['PercentMCLValue'] ||
                        deepEqual(errors['PercentMCLValue'], new Array(2))
                    ) {
                        setError(
                            'PercentMCLValue[1]',
                            'percentmcl',
                            'Please set Param/CAS # and % of MCL values'
                        )
                        // if there is an error from the single field, clear it
                        if (
                            errors['PercentMCLValue'] &&
                            !Array.isArray(errors['PercentMCLValue'])
                        ) {
                            clearError('PercentMCLValue')
                        }
                        return
                    }
                } else {
                    // if all the fields are populated, clear the error.
                    if (
                        errors['PercentMCLValue'] &&
                        !deepEqual(errors['PercentMCLValue'], new Array(2))
                    ) {
                        clearError('PercentMCLValue')
                    }
                }
            }
            // otherwise there is only one mcl value field, and we have some comparator operator active
            else if (percentMCLFields.length === 1) {
                // if the grouping has a value, and does not have a percent mcl value
                // or the percent mcl has a value, but grouping is not selected
                // then we throw an error
                if (!(mclGroupingValue && hasPercentMCLValue)) {
                    if (!errors['PercentMCLValue']) {
                        setError(
                            'PercentMCLValue',
                            'percentmcl',
                            'Please set Param/CAS # and % of MCL values.'
                        )
                        // if there is an error left over from the array (between) field, clear it
                        if (
                            errors['PercentMCLValue'] &&
                            Array.isArray(errors['PercentMCLValue'])
                        ) {
                            clearError('PercentMCLValue[1]')
                        }
                        return
                    }
                }
            }
        }
    }
}

const uploadValidate = () => {}

const formInputSelector = formType => {
    switch (formType) {
        case 'TextBox':
            return Input
        case 'Select':
            return Select
        case 'MultiSelect':
            return MultiSelect
        case 'DatePicker':
            return DateSelect
        case 'CheckBox':
            return Checkbox
        default:
            return null
    }
}

const uploadInputSelector = formType => {
    switch (formType) {
        case 'TextBox':
            return SubmissionInput
        case 'Select':
            return SubmissionSelect
        case 'MultiSelect':
            return SubmissionSelect
        case 'DatePicker':
            return DateTimeSelect
        case 'DateDayPicker':
            return SubmissionDateSelect
        case 'CheckBox':
            return Checkbox
        case 'Creatable':
            return SubmissionCreatable
        default:
            return null
    }
}

const comparisonOperatorParser = compareString => {
    return compareString ? compareString.split(';') : null
}

const registerParamConstructor = (field, applyRequired) => {
    const required = field.Required ? 'This field is required' : false

    const min = field.MinValue
        ? {
              value: field.MinValue,
              message: `Value cannot be less than ${field.MinValue}`,
          }
        : null

    const max = field.MaxValue
        ? {
              value: field.MaxValue,
              message: `Value cannot be greater than ${field.MaxValue}`,
          }
        : null

    const maxLength = field.MaxLength
        ? {
              value: field.MaxLength,
              message: `Max length of this field is ${field.MaxLength}`,
          }
        : null

    const validate = field.DataType === "Int" && field.ControlType === "TextBox" ? (value) => {
        return value ? parseInt(value) === parseFloat(value) || "Please enter an integer value for this field" : true
    } : null

    return {
        required: applyRequired ? required : false,
        min,
        max,
        maxLength,
        validate
    }
}

const getInputType = dataType => {
    switch (dataType) {
        case 'Int':
            return 'number'
        case 'Float':
            return 'number'
        default:
            return 'text'
    }
}

const formFieldParser = (
    formFields,
    register,
    unregister,
    control,
    getValues,
    setValue,
    setError,
    clearError,
    errors,
    formWidth,
    formName,
    hrColorClass,
    watch,
    validate,
    formInputSelector
) => {
    const fieldGroups = [...new Set(formFields.map(x => x.GroupName))]
    return fieldGroups.map((fieldGroup, fieldGroupIdx) => {
        const groupFields = formFields.filter(x => x.GroupName === fieldGroup)
        const description = formFields.find(x => x.GroupName === fieldGroup)
            .GroupDescription
        return (
            <React.Fragment key={`fieldGroup-${fieldGroupIdx}`}>
                {fieldGroup && (
                    <div className={`column is-full ${'no-vertical-padding'}`}>
                        <span className="label is-medium fieldGroupText">
                            {fieldGroup}
                        </span>
                        <hr
                            className={`is-primary fieldGroupSeparator ${hrColorClass}`}
                        ></hr>
                        {description && (
                            <div className="column is-full">
                                <span
                                    className="label is-small fieldGroupSeparatorText"
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                ></span>
                            </div>
                        )}
                    </div>
                )}
                {groupFields.map((field, idx) => {
                    // filter field Display controls
                    // whether it should be in the form
                    const display = field.DisplayInFilter
                    if (!display) {
                        return null
                    }

                    const formComponent = formInputSelector(field.ControlType)
                    const comparisonOperators = comparisonOperatorParser(
                        field.CompareItems
                    )
                    validate(
                        field,
                        getValues,
                        setValue,
                        errors,
                        setError,
                        clearError
                    )
                    const registerParams = registerParamConstructor(
                        field,
                        false
                    )
                    const type = getInputType(field.DataType)
                    const name = field.ControlName
                    const helper = field.Description
                    const fieldName = field.Prompt
                    const values = field.Values
                    const minValue = field.MinValue
                    const maxValue = field.MaxValue
                    const maxLength = field.MaxLength
                    const dataAccessor = field.DataAccessor
                    // const error = errors ? errors[`${name}Value`] : null
                    const fieldAccessorMap = {
                        label: 'codedescription',
                        value: 'code',
                    }
                    if (!formComponent) {
                        return null
                    }
                    return React.createElement(formComponent, {
                        register,
                        unregister,
                        key: `field-${idx}`,
                        control,
                        registerParams,
                        setValue,
                        clearError,
                        helper,
                        idx,
                        fieldName,
                        values,
                        name,
                        formName,
                        dataAccessor,
                        type,
                        comparisonOperators,
                        fieldAccessorMap,
                        errors,
                        formWidth,
                        minValue,
                        maxValue,
                        maxLength,
                        watch,
                    })
                })}
            </React.Fragment>
        )
    })
}

const uploadFieldParser = (
    formFields,
    formWidth,
    formName,
    hrColorClass,
    formInputSelector,
    viewOnly
) => {
    const fieldGroups = [
        ...new Set(formFields.map(x => x.UploadSectionGroupName)),
    ]
    return fieldGroups.map((fieldGroup, fieldGroupIdx) => {
        const groupFields = formFields.filter(
            x => x.UploadSectionGroupName === fieldGroup
        )
        // const description = formFields.find(x => x.UploadSectionGroupName === fieldGroup).GroupDescription
        return (
            <React.Fragment key={`fieldGroup-${fieldGroupIdx}`}>
                {groupFields.map((field, idx) => {
                    // filter field Display controls
                    // whether it should be in the form
                    const display = field.DisplayInFilter
                    if (!display) {
                        return null
                    }
                    const fieldProps = {
                        field,
                        formInputSelector,
                        getInputType,
                        validate: uploadValidate,
                        formWidth,
                        formName,
                        hrColorClass,
                        idx,
                        displayLabel: true,
                        key: `field-component-${idx}`
                    }

                    return <FieldComponent {...fieldProps} />
                })}
            </React.Fragment>
        )
    })
}

const FieldComponent = ({
    field,
    formInputSelector,
    getInputType,
    formWidth,
    formName,
    validate,
    idx,
    rowIdx,
    displayLabel
}) => {
    const {
        register,
        unregister,
        control,
        setValue,
        clearError,
        errors,
        watch,
    } = useFormContext()
    const formComponent = formInputSelector(field.ControlType)
    const comparisonOperators = comparisonOperatorParser(field.CompareItems)
    const registerParams = registerParamConstructor(field, true)
    const type = getInputType(field.DataType)
    const name = field.ColumnName
    const helper = field.Description
    const fieldName = field.Prompt
    const values = field.Values
    const minValue = field.MinValue
    const maxValue = field.MaxValue
    const maxLength = field.MaxLength
    const dataAccessor = field.DataAccessor
    const required = field.Required
    const defaultValue = field.DefaultValue
    const columnWidth = field.ColumnWidth
    const disabled = field.ViewOnly
    // const error = errors ? errors[`${name}Value`] : null
    const fieldAccessorMap = {
        label: 'codedescription',
        value: 'code',
        type: type,
        active: 'active'
    }
    if (!formComponent) {
        return null
    }
    return React.createElement(formComponent, {
        register,
        unregister,
        key: `field-${idx}`,
        control,
        registerParams,
        setValue,
        clearError,
        helper,
        idx,
        fieldName,
        values,
        name,
        formName,
        dataAccessor,
        type,
        columnWidth,
        comparisonOperators,
        fieldAccessorMap,
        errors,
        formWidth,
        minValue,
        maxValue,
        maxLength,
        defaultValue,
        watch,
        required,
        displayLabel,
        rowIdx,
        disabled
    })
}

export {
    getInputType,
    uploadInputSelector,
    uploadFieldParser,
    uploadValidate,
    formValidate,
    formInputSelector,
    FieldComponent
}
export default formFieldParser
