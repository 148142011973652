import React from 'react'
import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../Tooltip'
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from 'react-icons/fa'

export default ({
    name,
    expandedMenu,
    setExpandedMenu,
}) => {
    return (
        <div
            className={`panelHeaderIconDiv`}
            data-tip={`${
                expandedMenu === name
                    ? `Collapse`
                    : `Expand to Full Screen`
            }`}
            data-for={`${name}Expand`}
            onClick={ () => setExpandedMenu(current => current === name ? null : name) }
        >
            <IconContext.Provider
                value={{
                    className: `panelHeaderIcon`,
                }}
            >
                {expandedMenu === name ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
            </IconContext.Provider>
            <Tooltip id={`${name}Expand`} />
        </div>
    )
}




