import React, { createContext, useContext, useState, useEffect, useCallback, useMemo, } from 'react'

import toast from '../../../elem/Toast'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import filterTimeData, { getDataWithinTimePeriod, timeWindowOptions } from '../../../../utils/chart/timeWindow'
import { generateDateParams } from '../../../../utils/chart/values'
import { ParameterContext } from '../../../wrappers/ParameterContext'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    // get the detail id from the app state context 
    const { detailState } = useContext(AppStateContext)
    const { well: wellDetailState } = detailState
    const facilityID = wellDetailState.facilityID
    const { params: explorerParams } = useContext(ParameterContext)

    // get the API_URL from config
    const { API_URL } = config

    // loading state
    const [isLoading, setLoading] = useState(false)

    // data state
    const [detailData, setDetailData] = useState({})
    const [filterData, setFilterData] = useState({})
    const [visibleTab, setVisibleTab] = useState('well')
    const [visibleChartTab, setVisibleChartTab] = useState('sampleResult')

    const [paramResetExpanded, toggleParamResetExpanded] = useState(false)
    const [selectedParams, setSelectedParams] = useState([])
    const [displayParamWindowDropdown, toggleParamWindowDropdown] = useState(false)
    const [selectedUnits, setSelectedUnits] = useState(null)
    
    const [zoomTrigger, setZoomTrigger] = useState(false)

    // display state
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(false)
    
    const dateParams = useMemo(() => {
        const d = generateDateParams(explorerParams, 'sample', 'SampleDate')
        return d.some(x => x !== null) ? d : null
    }, [explorerParams])

    // fetch data on clearinghouseNumber change
    const fetchData = () => {
        if (facilityID) {
            setDetailData({})
            fetch(`${API_URL}/well/${facilityID}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setDetailData(response.data)
                    setFilterData(response.filterData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Detail: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
                .finally(() => setLoading(false))
        }
    }
    
    const resetZoom = useCallback(() => setZoomTrigger(!zoomTrigger), [zoomTrigger])

    useEffect(() => {
        resetZoom() // if data changes set the map boundaries to dataMin-Max in the chart 
        fetchData(facilityID)
    }, [facilityID])

    const paramChartData = useMemo(() => {
        if (detailData.paramChart && detailData.paramChart.length) {
            return dateParams
                ? getDataWithinTimePeriod(
                      detailData.paramChart,
                      dateParams,
                      'SampleDate'
                  )
                : filterTimeData(
                      detailData.paramChart,
                      timeWindow,
                      'SampleDate'
                  )
        }
        return []
    }, [detailData, timeWindow, dateParams])

    useEffect(() => {
        if (
            paramChartData &&
            paramChartData.length
        ) {
            const firstItem = paramChartData[0]
            setSelectedParams([firstItem])
        }
    }, [paramChartData])
    
    const paramList = useMemo(() => {
        if (paramChartData && paramChartData.length && detailData && detailData.paramChartDropdown) {
            // const activeAnalytes = [...new Set(paramChartData.map(x => x.Analyte))].map(x => detailData.paramChartDropdown.find(y => y.Analyte === x))
            const activeAnalytes = detailData.paramChartDropdown.filter((e) =>
                paramChartData.findIndex(a => a.ParamID === e.ParamID) !== -1
            )
            return activeAnalytes
        }
        return []
    }, [paramChartData, detailData])
    
    useEffect(() => {
        if (
            paramList &&
            paramList.length
        ) {
            const firstAnalyte = paramList[0]
            setSelectedParams([firstAnalyte])
        }
    }, [paramList])

    const toggleSelectedParam = useCallback(
        param => {
            if (selectedParams.find(x => x.ParamID === param.ParamID && selectedUnits === param.Units)) {
                setSelectedParams(
                    selectedParams.filter(x => x.ParamID !== param.ParamID)
                )
                return
            }
            if (selectedParams.length > 5) {
                toast({
                    level: 'info',
                    message:
                        'Too many parameters selected. Select up to 5 parameters to display.',
                    alert: true,
                })
                return 
            }

            const unit = param.Units
            if (selectedUnits && unit !== selectedUnits) {
                toast({
                    level: 'info',
                    message: `Please select a parameter that has the same units (${selectedUnits}) as the other parameters in the chart.`,
                    alert: true,
                })
            } else {
                setSelectedParams(selectedParams.concat(param))
            }
        }, [selectedParams, selectedUnits]
    )

    useEffect(() => {
        if (selectedParams.length) {
            setSelectedUnits(selectedParams[0].Units)
        } else {
            setSelectedUnits(null)
        }
    }, [selectedParams])
    
    const resetParams = useCallback(() => {
        const firstParam = paramList[0]
        if (firstParam) {
            setSelectedParams([firstParam])
        } else {
            setSelectedParams([])
        }
    }, [paramList])

    return (
        <DataContext.Provider
            value={{
                facilityID,
                isLoading,
                detailData,
                filterData,
                visibleTab,
                visibleChartTab,
                setVisibleChartTab,
                paramList,
                paramChartData,
                timeWindow,
                displayTimeWindowDropdown, 
                toggleTimeWindowDropdown,
                setTimeWindow,
                setVisibleTab,
                resetZoom,
                zoomTrigger,
                selectedParams,
                setSelectedParams,
                toggleSelectedParam,
                displayParamWindowDropdown,
                toggleParamWindowDropdown,
                paramResetExpanded, 
                toggleParamResetExpanded,
                resetParams,
                selectedUnits,
                setSelectedUnits,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
