import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaSyncAlt, FaCog } from 'react-icons/fa'

import urls, { isPageExplorer } from '../../../utils/constants/urls'
import Burger from './Burger'
import withConfig from '../../wrappers/withConfig'
import { UserContext } from '../../wrappers/UserContext'
import ExportButtons from './ExportButtons'
import RemainingLoginTime from '../../elem/user/RemainingLoginTime'
import {
    AlertButton,
    DisclaimerButton,
    HelpButton,
    PromoteAllButton,
} from './InformationalButtons'
import {
    hasAccessToAdmin,
    hasAccessToManageSampleResults
} from '../../../utils/user/permissions'

const Navbar = ({ config }) => {
    const { user, client, roles } = useContext(UserContext)
    const [burgerExpanded, toggleBurger] = useState(false)
    const location = useLocation()
    const { APP_BANNER_TITLE, HELP_TEXT, ALERT_TEXT, DISCLAIMER_TEXT } = config
    const isExplorer = isPageExplorer(location)
    
    return (
        <nav
            className="navbar is-fixed-top is-transparent has-shadow is-boxed"
            role="navigation"
            aria-label="main navigation"
        >
            <div className="navbar-brand">
                <div className="app-title mobile">{APP_BANNER_TITLE}</div>
                <Burger expanded={burgerExpanded} onClick={toggleBurger} />
            </div>
            <div
                id="navbarBasicExample"
                className={`navbar-menu ${burgerExpanded ? 'is-active' : ''}`}
            >
                <div className="navbar-start">
                    {/* <div className="navbar-item has-dropdown is-hoverable">
                        <div
                            className="navbar-link"
                            to={urls.home}
                            onClick={() => toggleBurger(false)}
                        >
                            Explorer
                        </div>

                        <div className="navbar-dropdown">
                            <Link
                                className={`navbar-item ${
                                    location.pathname === urls.home
                                        ? 'is-active'
                                        : ''
                                }`}
                                to={urls.home}
                                onClick={() => toggleBurger(false)}
                            >
                                All
                            </Link>
                            <Link
                                className={`navbar-item ${
                                    location.pathname === urls.well
                                        ? 'is-active'
                                        : ''
                                }`}
                                to={urls.well}
                                onClick={() => toggleBurger(false)}
                            >
                                Well Explorer
                            </Link>
                            <Link
                                className={`navbar-item ${
                                    location.pathname === urls.sample
                                        ? 'is-active'
                                        : ''
                                }`}
                                to={urls.sample}
                                onClick={() => toggleBurger(false)}
                            >
                                Sample Result Explorer
                            </Link>
                        </div>
                    </div> */}
                    <div className="navbar-item app-title desktop">
                        {APP_BANNER_TITLE}
                    </div>
                    {/* <div
                        className="navbar-item production-warning desktop"
                        style={{ color: 'red' }}
                    >
                        {`This is a non-production environment`}
                    </div> */}
                </div>

                <div className="navbar-end">
                    {isExplorer && <>
                        <div className="navbar-item">
                            <div className="buttons">
                                <PromoteAllButton />
                                <AlertButton text={ALERT_TEXT} />
                                <HelpButton text={HELP_TEXT} />
                                <DisclaimerButton text={DISCLAIMER_TEXT} />
                            </div>
                        </div>
                        <div className="navbar-item">
                            <div className="buttons">
                                <button className="button is-info is-small globalResetButton">
                                    <a href={location.pathname}>
                                        <FaSyncAlt />
                                        Reset All Filters
                                    </a>
                                </button>
                                <ExportButtons />
                            </div>
                        </div>
                    </>}
                    <div className="navbar-item has-dropdown is-hoverable">
                        <div className="navbar-link">
                            <FaCog />
                        </div>
                        <div className="navbar-dropdown is-right">
                            {user && <RemainingLoginTime />}
                            {user && hasAccessToManageSampleResults(roles) && (
                                <a 
                                    target="_blank"
                                    rel="opener"
                                    href={urls.manageSampleResults}
                                    className="navbar-item"
                                >
                                    Submit Sample Results
                                </a>
                            )}
                            <a
                                className="navbar-item"
                                target="_blank"
                                rel="opener"
                                href={urls.newRegistration}
                            >
                                New Registrations
                            </a>
                            {user ? (
                                <>
                                    {hasAccessToAdmin(roles) && (
                                        <a
                                            className="navbar-item"
                                            target="_blank"
                                            rel="opener"
                                            href={urls.manageUsers}
                                        >
                                            Admin
                                        </a>
                                    )}
                                     <a
                                        className="navbar-item"
                                        target="_blank"
                                        rel="opener"
                                        href={urls.changePassword}
                                    >
                                        Change Password
                                    </a>
                                    <div
                                        className="navbar-item clickable"
                                        onClick={function() {
                                            client.signoutRedirect()
                                        }}
                                    >
                                        Logout
                                    </div>
                                    
                                </>
                            ) : (
                                <>
                                    <div
                                        className="navbar-item clickable"
                                        onClick={function() {
                                            client.signinRedirect()
                                        }}
                                    >
                                        Login
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default withConfig(Navbar)
