import React, { useContext, useEffect } from 'react'

// import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import ParamSelectorButton from '../../../elem/chart/ParamSelectorButton'
import ResetDropDownButton from '../../../elem/chart/ResetDropDownButton'

import { DataContext } from './DataContext'
import { AppStateContext } from '../AppStateContext'
import ExpandButton from '../../../elem/list/ExpandButton'

const ChartControls = () => {
    const {
        // timeWindow,
        // displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayParamWindowDropdown,
        toggleParamWindowDropdown,
        visibleChartTab,
        paramResetExpanded,
        toggleParamResetExpanded,
    } = useContext(DataContext)
    
    const {expandedMenu, setExpandedMenu} = useContext(AppStateContext)

    return (
        <div className="chartControlsWrapper columns">
            <div className="column is-10">
                {visibleChartTab === 'sampleResult' 
                    ? (<>
                        <div className="columns is-justify-content-center">
                            <div>
                                <ResetDropDownButton 
                                    inHeader={false}
                                    resetExpanded={paramResetExpanded}
                                    toggleResetExpanded={toggleParamResetExpanded} 
                                />
                            </div>
                            <div>
                            <ParamSelectorButton
                                inHeader={false} 
                                displayParamWindowDropdown={displayParamWindowDropdown}
                                toggleParamWindowDropdown={toggleParamWindowDropdown}
                            />

                            </div>
                        </div>
                            {/* <TimeWindowSelectorButton
                                inHeader={false} 
                                timeWindow={timeWindow}
                                displayTimeWindowDropdown={displayTimeWindowDropdown}
                                toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                            /> */}
                    </>) : null
                }
                {visibleChartTab === 'depth' 
                    ? (<></>) : null
                }
            </div>
            <ExpandButton 
                name={`DetailSampleChart`}
                expandedMenu={expandedMenu}
                setExpandedMenu={setExpandedMenu}
            />            
        </div>
    )
}

export default ChartControls