import React, { useContext, useMemo } from 'react'

import TableContent from '../../../elem/table/ssr/TableContent'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { DataContext } from './DataContext'
import parseColumns from '../../../../utils/table/parseColumns'
import NoDataComponent from '../../../elem/table/ssr/NoDataComponent'
import withConfig from '../../../wrappers/withConfig'

const List = ({ filterExpanded, toggleFilter, config }) => {
    const dataContext = useContext(DataContext)
    const { params, setParams } = useContext(ParameterContext)
    const { EXPLORER_LINK_COLUMN, ID_COLUMN } = config
    const { data, loading, meta, filterFields } = dataContext
    const { pageCount, count, pageSize } = meta
    const adjustedPageCount = (count / pageSize) % 1 === 0 ? pageCount - 1 : pageCount
    const columns = useMemo(() => parseColumns(data, filterFields, EXPLORER_LINK_COLUMN, ID_COLUMN))
    if (count === 0) {
        return <NoDataComponent filterExpanded={filterExpanded} toggleFilter={toggleFilter} />
    }
    return (
        <TableContent
            name="well"
            columns={columns}
            data={data}
            loading={loading}
            pageCount={adjustedPageCount}
            resultsCount={count}
            params={params}
            setParams={setParams}
            filterFields={filterFields}
            filterExpanded={filterExpanded}
        />
    )
}

export default withConfig(List)