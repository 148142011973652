import deepEqual from 'deep-equal'
import React, { useState, useEffect, useContext, useCallback } from 'react'

import { DataContext } from '../DataContext'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import SectionWrapper from './SectionWrapper'

export default () => {
    const { activePanel, submitForValidation, isSubmitting, submissionState, storedSubmissionState } = useContext(DataContext)
    const { approveUpload, rejectUpload, reviewText, denied } = useContext(ExistingDataContext)
    const [comments, setComments] = useState(null)
    const [commentsError, setCommentsError ] = useState(null)
    const [valid, setValid] = useState(false)

    useEffect(() => {
        // on submission state change, set isvalid to false if it isn't already
        if (valid) {
            if (!deepEqual(submissionState, storedSubmissionState)) {
                setValid(false)
            }
        }
    }, [submissionState, storedSubmissionState, valid])

    const submit = useCallback(() => {
        if (valid) {
            approveUpload(comments)
        } else {
            submitForValidation(submissionState, true).then(() => {
                setValid(true)
            }).catch(() => {})
        }
    }, [valid, comments, approveUpload, submitForValidation, submissionState])

    const returnForm = useCallback(() => {
        if (comments) {
            rejectUpload(comments)
        } else {
            setCommentsError('Comments are required for denied submissions.')
        }
    }, [comments, rejectUpload])

    useEffect(() => {
        if (commentsError) {
            if (comments) {
                setCommentsError(null)
            }
        }
    }, [comments, commentsError])
    
    return (
        <div
            className={`columns is-multiline
            ${activePanel !== 'Review' ? 'is-hidden' : ''}
        `}
        >
            <div className="column is-6">
                <SectionWrapper title={'Review Submission'}>
                    <div className="content">
                        <p>
                            Once you are done reviewing changes, please either
                            approve this submission or deny it.
                        </p>
                    </div>
                    <div className="content">
                        <ul>
                            <li>
                                <b>Approve</b> will merge the changes into the
                                Clearinghouse database
                            </li>
                            <li>
                                <p>
                                    <b>Deny</b> will not merge the changes into the Clearinghouse database
                                </p>
                                <p>
                                    <i>
                                        Note: Comments are required with a
                                        denied form.
                                    </i>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="content">
                        <form className="form">
                            <div className="column is-12">
                                <div className="control">
                                    <div className="label">Comments:</div>
                                    <div className="field">
                                        <textarea className={`textarea ${commentsError ? 'is-danger' : ''}`} onChange={(e) => {setComments(e.target.value)}}/>
                                    </div>
                                    {commentsError && (<div className="help is-danger">
                                        {commentsError}
                                    </div>)}
                                </div>
                            </div>
                            <div className="column is-10">
                                <div className="buttonWrapper">
                                    <button
                                        type="button"
                                        className={`button is-link is-medium ${isSubmitting ? 'is-loading' : ''}`}
                                        disabled={denied ? true : false}
                                        onClick={() => submit()}
                                        >
                                        {valid ? `Approve` : `Validate`} Form
                                    </button>
                                    <button
                                        type="button"
                                        // className="button is-medium"
                                        className={`button is-medium ${denied ? 'is-loading' : ''}`}
                                        disabled={isSubmitting ? true : false}
                                        onClick={() => returnForm()}
                                        >
                                        Deny Form
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </SectionWrapper>
            </div>
            <div className="column is-6">
                {reviewText && reviewText.length ? (
                    <SectionWrapper title={"Deleted Records"}>
                        <div className="content">
                            <ul>
                                {reviewText.map(x => <li><p>{x}</p></li>)}
                            </ul>
                        </div>
                    </SectionWrapper>
                ) : null}
            </div>
        </div>
    )
}
