import React, { useEffect } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'

import TableHeader from '../shared/TableHeader'
import TableFooter from './TableFooter'
import TableBody from '../shared/TableBody'
import { convertFromParams } from '../../../../utils/table/sortBy'
import getAppliedFilterString from '../../../../utils/table/getAppliedFilterString'

const Table = ({
    name,
    columns,
    data,
    loading,
    pageCount: controlledPageCount,
    resultsCount,
    filterExpanded,
    filterFields,
    setParams,
    params,
}) => {
    const initialPageIndex = (params && params[name]) ? Number(params[name].Page) - 1 : 0
    const initialPageSize = (params && params[name]) ? Number(params[name].PageSize) : 50
    const initialSortBy = (params && params[name]) ? convertFromParams(params[name].SortByField, params[name].SortOrder) : []
    const initialPage = (params && params[name]) ? params[name].Page : null
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
                sortBy: initialSortBy,
                hiddenColumns: ['FacilityID'],
            }, // Pass our hoisted table state
            manualPagination: true, // pagination
            manualSorting: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            pageCount: controlledPageCount,
        },
        useSortBy,
        usePagination
    )

    // page info fetch
    useEffect(() => {
        const sortByProps = sortBy.length
            ? {
                  SortByField: `${sortBy[0].id}`,
                  SortOrder: sortBy[0].desc ? 'DESC' : 'ASC',
              }
            : {}
        const newParams = {
            ...params[name], Page: ( pageIndex + 1 ), PageSize: pageSize, ...sortByProps 
        }
        setParams(newParams, name)
    }, [pageIndex, pageSize, sortBy])

    useEffect(() => {
        if (initialPage) {
            gotoPage(initialPage - 1)
        } 
    }, [initialPage])


    // Render the UI for your table
    return (
        <>
            <div
                className={`container table-container ${
                    filterExpanded ? 'is-hidden' : 'is-visible'
                } explorerTableWrapper`}
            >
                <div className="filterSummaryWrapper">
                    <div className="filterSummaryText">
                        {getAppliedFilterString(params[name], filterFields)}
                    </div>
                </div>
                <table
                    {...getTableProps()}
                    className="table is-striped is-fullwidth is-hoverable explorerTable"
                >
                    <TableHeader headerGroups={headerGroups} />
                    <TableBody
                        {...{
                            getTableBodyProps,
                            page,
                            prepareRow,
                            loading,
                            controlledPageCount,
                            pageSize,
                        }}
                    />
                </table>
            </div>
            <TableFooter
                {...{
                    filterExpanded,
                    gotoPage,
                    previousPage,
                    canPreviousPage,
                    nextPage,
                    canNextPage,
                    pageCount,
                    resultsCount,
                    pageOptions,
                    pageIndex,
                    pageSize,
                    setPageSize,
                    loading,
                }}
            />
        </>
    )
}

export default Table
