import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import NoDataComponent from '../../../elem/table/client/NoDataComponent'
import TableContent from '../../../elem/table/client/TableContent'
import parseColumns from '../../../../utils/table/parseColumns'
import WithConfig from '../../../wrappers/withConfig'

export default WithConfig(({ config }) => {
    const { detailData, filterData, visibleTab, isLoading } = useContext(DataContext)
    const { ID_COLUMN, EXPLORER_LINK_COLUMN } = config
    if (
            detailData &&
            detailData.samples &&
            filterData &&
            filterData.samples &&
            visibleTab === 'samples'
    ) {
        const formFields = filterData.samples
        const sampleDetailsData = detailData.samples
        const columns = parseColumns(sampleDetailsData, formFields, EXPLORER_LINK_COLUMN, ID_COLUMN)
        return (
            <div className="is-size-7 sampleDetailWrapper">
                <NoDataComponent data={sampleDetailsData} isLoading={isLoading}/>
                <TableContent
                    name="sampleDetails"
                    columns={columns}
                    data={sampleDetailsData}
                    containerClass={'detailTableWrapper'}
                    loading={isLoading}
                />
            </div>
        )

    } else {
        return null
    }
})