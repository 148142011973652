import React, { useContext } from 'react'

import { DataContext } from '../DataContext'
import ParamSelector from '../../../../elem/chart/ParamSelector'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'

export default () => {
    const {
        selectedParams,
        toggleSelectedParam,
        displayParamWindowDropdown,
        toggleParamWindowDropdown,
        paramList
    } = useContext(DataContext)
    const { colorClass } = useContext(PanelStyleContext)

    if (!paramList || !paramList.length) {
        return null
    }

    return (
        <ParamSelector
            selectedParams={selectedParams}
            paramList={paramList}
            toggleSelectedParam={toggleSelectedParam}
            toggleParamWindowDropdown={toggleParamWindowDropdown}
            displayParamWindowDropdown={displayParamWindowDropdown}
            colorClass={colorClass}
        />
    )
}