import React, { useContext } from 'react'
import { AppStateContext } from '../AppStateContext'
import { FaTimes } from 'react-icons/fa'

import PanelHeader from '../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import TimeWindowSelectorButton from '../../../elem/chart/TimeWindowSelectorButton'
import ResetZoomButton from '../../../elem/chart/ResetZoomButton'

export default () => {
    const { updateDetailState } = useContext(AppStateContext)
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        resetZoom,
    } = useContext(DataContext)

    return (
        <PanelHeader extraClass="wellDetailPanelHeader">
            <div className="panelHeaderText">Details</div>
            <div className="panelButtons">
                {/* <ResetZoomButton resetFunction={resetZoom} />
                <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                /> */}
                <div
                    className="panelButton"
                    onClick={e => {
                        e.preventDefault()
                        updateDetailState('well', { visible: false })
                    }}
                >
                    <FaTimes />
                </div>
            </div>
        </PanelHeader>
    )
}
