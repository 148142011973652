import React from 'react'
import { DownArrow, UpArrow } from '../Arrows'

const ResetDropDownButton = ({ resetExpanded, toggleResetExpanded, inHeader=true }) => (
    <div
        className="panelButton timeWindowOptionWrapper"
        onClick={() => toggleResetExpanded(!resetExpanded)}
    >
        <span className="panelTimeOptionText">Reset</span>
        <span className="panelTimeOptionDropdown">
            {resetExpanded ? <UpArrow className={`${inHeader ? 'is-white' : ''}`}/> : <DownArrow className={`${inHeader ? 'is-white' : ''}`}/>}
        </span>
    </div>
)

export default ResetDropDownButton